import React, { useState } from "react";
import { useProductsContext } from "../../context/products-context";
import { navigate } from "@reach/router";
import { useEffect } from "react";
import './init-basket.scss'
import { useNewCheckoutContext } from "../../context/checkout-context-v2";

const AgregatorBasket = () => {
  const {

    getQuoteDetails,
    checkDemandandNeeds,
    quoteDetails: { code },
    needsAndDemandsCode,
    redirectToEventsCheckout,
    isRedirected,
    getQuoteDetailsLoading, quoteIdError
  } = useProductsContext();


  const { getQuote, redirectToVehiclesCheckout, resetCustomerData} = useNewCheckoutContext();


  let id;
  let quoteId


  if (typeof window !== "undefined" && window.location.pathname.includes('init-basket') ) {
    const queryParams = new URLSearchParams(window.location.search);
    id = queryParams.get("id");
    quoteId = queryParams.get("quoteId");
  }

  const [storedQuoteDetails, setStoredQuoteDetails] = useState()


  useEffect(async () => {
    
    const path = `${window.location.pathname}?id=${id}&quoteId=${quoteId}`;
    navigate(path);

    //thease two calls are used for both vehicle and wedding and the result is stored in sessionStorage
    await getQuoteDetails(quoteId,id);
    if(typeof window !== "undefined"){
      
      if(window.sessionStorage.getItem("quoteDetailsEvent")){
        setStoredQuoteDetails(JSON.parse(sessionStorage.getItem("quoteDetailsEvent")))
      }
      else if(window.sessionStorage.getItem("quoteDetailsVehicle")){
        setStoredQuoteDetails(JSON.parse(sessionStorage.getItem("quoteDetailsVehicle")))

      }
      else{

        setStoredQuoteDetails('');
      }
    }
    await checkDemandandNeeds(quoteId,id);

  

  }, []);



  useEffect(() => {
   

  const timer = setTimeout(() => {
      if(storedQuoteDetails?.ProductTypes[0] === 'Events') {
        window.sessionStorage.setItem("redirectFlagEvent", JSON.stringify(true));
      redirectToEventsCheckout();
    } else {
      window.sessionStorage.setItem("redirectFlagVehicle", JSON.stringify(true));
      redirectToVehiclesCheckout()
      getQuote();
    }
    }, 1000);
    return () => clearTimeout(timer);

  }, [needsAndDemandsCode, code, isRedirected]);




  let quoteDetailsCode 
  if (typeof window !== "undefined"){

    if(window.sessionStorage.getItem("quoteDetailsEvent")){
      quoteDetailsCode = (JSON.parse(sessionStorage.getItem("quoteDetailsEvent"))).code

    }
    else if(window.sessionStorage.getItem("quoteDetailsVehicle")){
      quoteDetailsCode = (JSON.parse(sessionStorage.getItem("quoteDetailsVehicle"))).code

    }
  }
   

  return <div className="init-basket-container" >
    {getQuoteDetailsLoading  ? (
      <svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={5}></circle>

    </svg>
    ) : ''
     
    }
    {
      quoteIdError ? 'Something went wrong, you may try again or please contact our support team!' :''
    }
  </div>;
};

export default AgregatorBasket;
