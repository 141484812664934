import React from "react";
import Layout from "../layouts";
import AgregatorBasket from "../components/init-basket/init-basket";

const InitBasket = () => {
  return (
    <Layout>
      {" "}
      <AgregatorBasket />{" "}
    </Layout>
  );
};

export default InitBasket;
